import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    annotationPlugin
);


export const ChartLine = ({ data }) => {

    const [chartData, setChartData] = useState({
        labels: Array.from({ length: 390 }, (_, i) => i),
        datasets: [],
    });

    const [chartOptions, setChartOptions] = useState({});

    const pct_change = (data.price[data.price.length - 1] / data.prevclose - 1.0) * 100;



    function formatWithSign(num) {
        return `${num >= 0 ? '+' : ''}${Math.round(num * 10) / 10}`;
    }

    useEffect(() => {
        setChartData({
            labels: Array.from({ length: 390 }, (_, i) => i),
            datasets: [
                {
                    label: "Price",
                    data: data.price,
                    borderColor: '#2196f3',  // Set line color to blue
                    backgroundColor: 'transparent',  // Ensure that the area below the line is transparent
                    borderWidth: 1,
                    pointRadius: 1.5
                },
                {
                    label: "Pred",
                    data: data.pred,
                    borderColor: '#f50057',
                    backgroundColor: 'transparent',
                    borderWidth: 0.5,
                    pointRadius: 1.0,        // Set point radius to 0 to hide points
                }
            ],
        });

        const lev_symbols = ["TQQQ", "SOXL", "UPRO", "UDOW"];
        let ch_shift = 1.5 / 100;

        if (lev_symbols.includes(data.symbol)) {
            ch_shift = 3 * ch_shift;
        }

        setChartOptions({
            animation: {
                duration: 0 // No animation
            },
            plugins: {
                title: {
                    display: true,
                    text: data.symbol + " " + formatWithSign(pct_change) + "%",
                    font: {
                        size: 24// Set your desired title font size here
                    },
                    color: 'lightgray',
                },
                legend: {
                    display: false, // This will hide the legend
                },
                annotation: {
                    annotations: {
                        prevclose: { // Unique ID for this annotation
                            type: 'line',
                            yMin: data.prevclose, // Replace with the Y value where you want the line to be drawn
                            yMax: data.prevclose, // Same value to make it horizontal
                            borderColor: 'lightgray',
                            borderWidth: 2,
                            label: {
                                enabled: false,
                            }
                        },
                        high: { // Unique ID for this annotation
                            type: 'line',
                            yMin: data.prevclose * (1 + ch_shift), // Replace with the Y value where you want the line to be drawn
                            yMax: data.prevclose * (1 + ch_shift), // Same value to make it horizontal
                            borderColor: 'lightgray',
                            borderWidth: 2,
                            borderDash: [10, 5],
                            label: {
                                enabled: false,
                            }
                        },
                        low: { // Unique ID for this annotation
                            type: 'line',
                            yMin: data.prevclose * (1 - ch_shift), // Replace with the Y value where you want the line to be drawn
                            yMax: data.prevclose * (1 - ch_shift), // Same value to make it horizontal
                            borderColor: 'lightgray',
                            borderWidth: 2,
                            borderDash: [10, 5],
                            label: {
                                enabled: false,
                            }
                        }

                    }
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    type: 'linear',  // Or 'time', 'category', etc.
                    // Additional options
                    grid: { color: 'transparent' },
                },
                y: {
                    type: 'linear',
                    // Additional options
                    grid: { color: 'transparent' },
                }
            },
        });
    }, [data, pct_change]);

    const chartRef = useRef(null); // Reference to the chart and download

    const downloadChart = () => {
        if (chartRef.current) {
            const canvas = chartRef.current.canvas;
            const imageUrl = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = imageUrl;
            link.download = 'chart.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const downloadIcon = `${process.env.PUBLIC_URL}/icons8-download-50.png`;

    return (
        <Box sx={{
            width: "100%", height: "400px", mx: "12px", my: "12px", position: 'relative',
        }}>
            <Line ref={chartRef} data={chartData} options={chartOptions} style={{ width: "80%" }} />
            <Button onClick={downloadChart}
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 10,
                    color: 'grey',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    }
                }}>
                <img src={downloadIcon} alt="Download" style={{ height: '20px', width: '20px' }} />
            </Button>
        </Box >
    );
};
