export function getNewYorkTime() {
    const date = new Date();
    const nyDate = new Date(date.toLocaleString("en-US", { timeZone: "America/New_York" }));
    return nyDate;
}

export function getMunitesFromOpen() {
    // Create a date object for the current time in New York
    const now = new Date(new Date().toLocaleString("en-US", { timeZone: "America/New_York" }));

    // Create a date object for 9:30 AM on the current day in New York
    const openingTime = new Date(now);
    openingTime.setHours(9, 30, 0, 0); // set time to 9:30 AM

    // Calculate the difference in minutes
    const difference = (now - openingTime) / (1000 * 60); // convert milliseconds to minutes

    return Math.round(difference); // round to the nearest minute
}


export function isWeekday() {
    const date = new Date();
    const nyDate = new Date(date.toLocaleString("en-US", { timeZone: "America/New_York" }));
    const dayOfWeek = nyDate.getDay();
    return dayOfWeek > 0 && dayOfWeek < 6; // 0 is Sunday, 6 is Saturday
}