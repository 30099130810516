import './App.css';
import * as Realm from "realm-web";
import React, { useEffect, useState } from 'react';
import { ChartLine } from './components/ChartLine';
// import { Row, Col } from 'antd';
import { getMunitesFromOpen, isWeekday } from './Util';
import Grid from '@mui/material/Grid';
import LoginForm from './components/LoginForm';

function App() {
  const [dbResults, setDBResults] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (isLoggedIn) {
      // Your existing fetchData function
      const fetchData = async () => {
        // use the logged-in user's credentials to access the database
        const mongo = user.mongoClient("mongodb-atlas");
        const db = mongo.db("bigdata");
        const collection = db.collection("intra");
        const results = await collection.find();
        // positions and order 
        const orderLookup = { "QQQ": 1, "SMH": 3, "IWM": 5, "SPY": 6, "DIA": 8, "TQQQ": 32, "SOXL": 34, "UPRO": 37, "UDOW": 39 };
        results.sort((a, b) => orderLookup[a.symbol] - orderLookup[b.symbol]);

        if (results && Array.isArray(results)) {
          setDBResults(results);
        } else {
          console.warn("Fetched data is not an array or is empty.");
        }
      };

      const run = async () => {
        await fetchData();
        const minutes = getMunitesFromOpen();
        let intervalId;
        if (isWeekday() && minutes > 0 && minutes < 390) {
          intervalId = setInterval(fetchData, 60000); // every minute during trading hours
        } else {
          intervalId = setInterval(fetchData, 600000); // every 10 minutes outside trading hours
        }

        // Clear interval on cleanup
        return () => {
          if (intervalId) {
            clearInterval(intervalId);
          }
        };
      };

      if (isLoggedIn) {
        run();
      }
    }
  }, [isLoggedIn, user]); // This will re-run when isLoggedIn changes

  // Handler for logging in
  const handleLogin = async ({ email, password }) => {
    try {
      const app = new Realm.App({ id: "application-0-veevf" });
      const credentials = Realm.Credentials.emailPassword(email, password);
      const loggedInUser = await app.logIn(credentials);
      setUser(loggedInUser);
      setIsLoggedIn(true);
    } catch (err) {
      console.error("Failed to log in", err);
      // You can set error state and show error message to the user
    }
  };


  if (!isLoggedIn) {
    return (
      <LoginForm onLoginSuccess={handleLogin}></LoginForm>
    );
  }

  if (dbResults === null) {
    return <div>Loading data...</div>;
  }



  return (
    <div className="App">
      <Grid container spacing={3}> {/* This replaces the Row component */}
        {dbResults.map((val, index) => (
          <Grid item key={index} xs={12} sm={12} md={6} lg={6} xl={6}  > {/* This replaces the Col component */}
            <ChartLine data={val} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default App;
